import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/smartnuance/blog/src/components/Layout/index.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const Container = makeShortcode("Container");
const TitleSection = makeShortcode("TitleSection");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title="Privacy" mdxType="SEO" />
    <Container section mdxType="Container">
  <TitleSection title="Privacy" subtitle="Let's make it simple" mdxType="TitleSection" />
      <p>{`  This website respects your privacy by not storing or collecting any data. There are no client-side analytics or third-party trackers of any kind. Enjoy this unique experience in the web.`}</p>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      